import { on } from 'delegated-events';

import { focusFirstFocusableChild } from '@prairielearn/browser-utils';

// The default behavior conflicts with our focusing behavior below.
window.bootstrap.Modal.Default.focus = false;

// Focus the first focusable thing when the popover is opened.
on('shown.bs.modal', 'body', (e) => {
  focusFirstFocusableChild(e.target as HTMLElement);
});
