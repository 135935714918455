import { observe } from 'selector-observer';

import {
  formatDateFriendly,
  formatDateFriendlyString,
  formatDateRangeFriendly,
  formatDateRangeFriendlyString,
} from '../../../src/lib/formatter.js';

interface FormatDateFriendlyTarget {
  element: HTMLElement;
  date: Date;
  timezone: string;
  options: Parameters<typeof formatDateFriendly>[2];
}

interface FormatDateRangeFriendlyTarget {
  element: HTMLElement;
  start: Date;
  end: Date;
  timezone: string;
  options: Parameters<typeof formatDateRangeFriendly>[3];
}

let formatDateFriendlyLiveUpdate = false;
let formatDateRangeFriendlyLiveUpdate = false;

const formatDateFriendlyTargets: FormatDateFriendlyTarget[] = [];
const formatDateRangeFriendlyTargets: FormatDateRangeFriendlyTarget[] = [];

/**
 * Live-update the rendering of dates from `formatDateFriendly()` to ensure that
 * relative date and time components (e.g., "today") are always correct. The
 * elements to live-update will be marked with the class
 * `.js-format-date-friendly-live-update` and have the date for the display
 * stored in an attribute called `data-format-date`.
 */
observe('span.js-format-date-friendly-live-update', {
  constructor: HTMLElement,
  add(el) {
    setupFormatDateFriendlyLiveUpdate(el);
  },
});

/**
 * Live-update the rendering of dates from `formatDateRangeFriendly()` to ensure
 * that relative date and time components (e.g., "today") are always correct.
 * The elements to live-update will be marked with the class
 * `.js-format-date-range-friendly-live-update` and have the date for the
 * display stored in an attribute called `data-format-date-range`.
 */
observe('span.js-format-date-range-friendly-live-update', {
  constructor: HTMLElement,
  add(el) {
    setupFormatDateRangeFriendlyLiveUpdate(el);
  },
});

/**
 * Set up the live-updating of a `formatDateFriendly()` display for the given element.
 *
 * @param el The span containing the date to update.
 */
function setupFormatDateFriendlyLiveUpdate(el: HTMLElement) {
  const encodedData = el.getAttribute('data-format-date');
  if (!encodedData) {
    throw new Error(`Element does not have data-format-date attribute data: ${el}`);
  }
  const data = JSON.parse(encodedData);
  formatDateFriendlyTargets.push({
    element: el,
    date: new Date(data.date),
    timezone: data.timezone,
    options: data.options,
  });
  if (!formatDateFriendlyLiveUpdate) {
    formatDateFriendlyLiveUpdate = true;
    setInterval(formatDateFriendlyUpdate, 1_000);
  }
}

/**
 * Set up the live-updating of a `formatDateRangeFriendly()` display for the
 * given element.
 *
 * @param el The span containing the date to update.
 */
function setupFormatDateRangeFriendlyLiveUpdate(el: HTMLElement) {
  const encodedData = el.getAttribute('data-format-date-range');
  if (!encodedData) {
    throw new Error(`Element does not have data-format-date-range attribute data: ${el}`);
  }
  const data = JSON.parse(encodedData);
  formatDateRangeFriendlyTargets.push({
    element: el,
    start: new Date(data.start),
    end: new Date(data.end),
    timezone: data.timezone,
    options: data.options,
  });
  if (!formatDateRangeFriendlyLiveUpdate) {
    formatDateRangeFriendlyLiveUpdate = true;
    setInterval(formatDateRangeFriendlyUpdate, 1_000);
  }
}

/**
 * Re-render all live-updating `formatDateFriendly` strings.
 */
function formatDateFriendlyUpdate() {
  for (const { element, date, timezone, options } of formatDateFriendlyTargets) {
    const display = formatDateFriendlyString(date, timezone, options);
    element.innerHTML = display;
  }
}

/**
 * Re-render all live-updating `formatDateRangeFriendly` strings.
 */
function formatDateRangeFriendlyUpdate() {
  for (const { element, start, end, timezone, options } of formatDateRangeFriendlyTargets) {
    const display = formatDateRangeFriendlyString(start, end, timezone, options);
    element.innerHTML = display;
  }
}
