import { observe } from 'selector-observer';

/**
 * Show elements based on whether an input has a specific value
 *
 * Example:
 *  <input type="text" id="my-input" />
 *  <input type="text" data-show-by="#my-input" data-show-value="abc" />
 */

observe('[data-show-by]', {
  constructor: HTMLElement,
  add(el) {
    initShowObserver(el);
  },
});

observe('[data-show-by-not]', {
  constructor: HTMLElement,
  add(el) {
    initShowNotObserver(el);
  },
});

function initShowObserver(el: HTMLElement) {
  const inputSelector = el.getAttribute('data-show-by');
  if (!inputSelector) return;

  const input = document.querySelector<HTMLInputElement>(inputSelector);
  if (!input) return;

  const showValue = el.getAttribute('data-show-value');
  if (showValue == null) return;

  const toggleShow = () => {
    if (input.value === showValue) {
      el.removeAttribute('hidden');
    } else {
      el.setAttribute('hidden', 'hidden');
    }
  };

  input.addEventListener('change', toggleShow);
  toggleShow();
}

function initShowNotObserver(el: HTMLElement) {
  const inputSelector = el.getAttribute('data-show-by-not');
  if (!inputSelector) return;

  const input = document.querySelector<HTMLInputElement>(inputSelector);
  if (!input) return;

  const showValueNot = el.getAttribute('data-show-value-not');
  if (showValueNot == null) return;

  const toggleShow = () => {
    if (input.value != showValueNot) {
      el.removeAttribute('hidden');
    } else {
      el.setAttribute('hidden', 'hidden');
    }
  };

  input.addEventListener('change', toggleShow);
  toggleShow();
}
