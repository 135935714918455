import { observe } from 'selector-observer';

/**
 * Enable elements based on the state of a checkbox
 *
 * Example:
 *  <input type="checkbox" id="my-checkbox" />
 *  <input type="text" data-enabled-by="#my-checkbox" />
 *
 * or the same but where the checkbox needs to be un-checked to enable the input:
 *  <input type="checkbox" id="my-checkbox" />
 *  <input type="text" data-enabled-by-not="#my-checkbox" />
 */

observe('[data-enabled-by]', {
  constructor: HTMLElement,
  add(el) {
    initEnabledObserver(el);
  },
});

observe('[data-enabled-by-not]', {
  constructor: HTMLElement,
  add(el) {
    initEnabledNotObserver(el);
  },
});

function initEnabledObserver(el: HTMLElement) {
  const checkboxSelector = el.getAttribute('data-enabled-by');
  if (!checkboxSelector) return;

  const checkbox = document.querySelector<HTMLInputElement>(checkboxSelector);
  if (!checkbox) return;

  const toggleEnabled = () => {
    if (checkbox.checked) {
      el.removeAttribute('disabled');
    } else {
      el.setAttribute('disabled', 'disabled');
    }
  };
  checkbox.addEventListener('change', toggleEnabled);
  toggleEnabled();
}

function initEnabledNotObserver(el: HTMLElement) {
  const checkboxSelector = el.getAttribute('data-enabled-by-not');
  if (!checkboxSelector) return;

  const checkbox = document.querySelector<HTMLInputElement>(checkboxSelector);
  if (!checkbox) return;

  const toggleEnabled = () => {
    if (!checkbox.checked) {
      el.removeAttribute('disabled');
    } else {
      el.setAttribute('disabled', 'disabled');
    }
  };
  checkbox.addEventListener('change', toggleEnabled);
  toggleEnabled();
}
