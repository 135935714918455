import { observe } from 'selector-observer';

/**
 * Show/hide elements based on the state of a checkbox
 *
 * Example:
 *  <input type="checkbox" id="my-checkbox" />
 *  <input type="text" data-show-by-checkbox="#my-checkbox" />
 *
 * or the same but where the checkbox needs to be un-checked to show the input:
 *  <input type="checkbox" id="my-checkbox" />
 *  <input type="text" data-show-by-checkbox-not="#my-checkbox" />
 */

observe('[data-show-by-checkbox]', {
  constructor: HTMLElement,
  add(el) {
    initEnabledObserver(el);
  },
});

observe('[data-show-by-checkbox-not]', {
  constructor: HTMLElement,
  add(el) {
    initEnabledNotObserver(el);
  },
});

function initEnabledObserver(el: HTMLElement) {
  const checkboxSelector = el.getAttribute('data-show-by-checkbox');
  if (!checkboxSelector) return;

  const checkbox = document.querySelector<HTMLInputElement>(checkboxSelector);
  if (!checkbox) return;

  const toggleEnabled = () => {
    if (checkbox.checked) {
      el.removeAttribute('hidden');
    } else {
      el.setAttribute('hidden', 'hidden');
    }
  };
  checkbox.addEventListener('change', toggleEnabled);
  toggleEnabled();
}

function initEnabledNotObserver(el: HTMLElement) {
  const checkboxSelector = el.getAttribute('data-show-by-checkbox-not');
  if (!checkboxSelector) return;

  const checkbox = document.querySelector<HTMLInputElement>(checkboxSelector);
  if (!checkbox) return;

  const toggleEnabled = () => {
    if (!checkbox.checked) {
      el.removeAttribute('hidden');
    } else {
      el.setAttribute('hidden', 'hidden');
    }
  };
  checkbox.addEventListener('change', toggleEnabled);
  toggleEnabled();
}
