/**
 * Prevent form submission when pressing Enter. This is helpful on large forms
 * where we don't want to accidentally submit.
 *
 * Example:
 *    modal = document.getElementById('myModal');
 *    preventSubmitOnEnter(modal);
 */

import { observe } from 'selector-observer';

observe('.js-prevent-submit-on-enter', {
  constructor: HTMLElement,
  add(el) {
    preventSubmitOnEnter(el);
  },
});

export function preventSubmitOnEnter(element: HTMLElement | null) {
  element?.addEventListener('keypress', (e) => {
    if (e.key == 'Enter') {
      e.preventDefault();
    }
  });
}
