import Clipboard from 'clipboard';
import { observe } from 'selector-observer';

observe('.btn-copy', {
  constructor: HTMLElement,
  initialize(el) {
    const clipboard = new Clipboard(el);
    clipboard.on('success', (e) => {
      const popover = new window.bootstrap.Popover(e.trigger, {
        content: 'Copied!',
        placement: 'bottom',
      });
      popover.show();
      window.setTimeout(function () {
        popover.dispose();
      }, 1000);
    });
  },
});
