import Cookies from 'js-cookie';

import { onDocumentReady } from '@prairielearn/browser-utils';

onDocumentReady(() => {
  document.querySelector('#dev-user-admin')?.addEventListener('click', () => {
    Cookies.set('pt_requested_uid', 'dev-admin@example.com', { path: '/' });
    location.reload();
  });

  document.querySelector('#dev-user-owner')?.addEventListener('click', () => {
    Cookies.set('pt_requested_uid', 'dev-owner@example.com', { path: '/' });
    location.reload();
  });

  document.querySelector('#dev-user-editor')?.addEventListener('click', () => {
    Cookies.set('pt_requested_uid', 'dev-editor@example.com', { path: '/' });
    location.reload();
  });

  document.querySelector('#dev-user-proctor')?.addEventListener('click', () => {
    Cookies.set('pt_requested_uid', 'dev-proctor@example.com', { path: '/' });
    location.reload();
  });

  document.querySelector('#dev-user-student')?.addEventListener('click', () => {
    Cookies.set('pt_requested_uid', 'dev-student@example.com', { path: '/' });
    location.reload();
  });
});
