import htmx from 'htmx.org/dist/htmx.js';

// Poor man's error handling: this ensures that if we get an unexpected error
// while processing an htmx request, we'll show an alert to the user.
//
// If individual htmx bits need more sophisticated error handling, they can
// handle this element and cancel it so it doesn't bubble up to this handler.
htmx.on('htmx:responseError', () => {
  alert('An unexpected error occurred. Please try again.');
});

// Collaborates with `src/lib/htmx-assets.ts` to ensure that the client will
// reload the page if any assets have changed.
let assetsVersion: string | null = null;
htmx.on('htmx:configRequest', (e: any) => {
  if (!assetsVersion) {
    assetsVersion =
      document.head.querySelector('meta[name="HX-Assets-Version"]')?.getAttribute('content') ??
      null;
  }

  e.detail.headers['HX-Assets-Version'] = assetsVersion;
});

declare global {
  interface Window {
    htmx: typeof htmx;
  }
}

window.htmx = htmx;
