import { type Popover } from 'bootstrap';
import { on } from 'delegated-events';
import { observe } from 'selector-observer';

import { focusFirstFocusableChild, onDocumentReady, trapFocus } from '@prairielearn/browser-utils';

function getPopoverContainerForTrigger(trigger: HTMLElement): HTMLElement | null {
  const popoverId = trigger.getAttribute('aria-describedby');
  if (!popoverId) return null;
  const popoverContainer = document.querySelector<HTMLElement>(`#${popoverId}`);
  return popoverContainer;
}

/**
 * By default, Bootstrap popovers aren't meant to contain interactive content,
 * but we use them very frequently for inline editing interfaces. We need to
 * layer some additional behavior on top of Bootstrap's behavior to make them
 * more accessible and easier to use with keyboards:
 */
onDocumentReady(() => {
  observe('[data-bs-toggle="popover"]', {
    add: (el) => {
      new window.bootstrap.Popover(el, { sanitize: false });
    },
    remove: (el) => {
      window.bootstrap.Popover.getInstance(el)?.dispose();
    },
  });

  const openPopovers = new Set<Popover>();

  function closeOpenPopovers() {
    openPopovers.forEach((popover) => popover.hide());
    openPopovers.clear();
  }

  on('inserted.bs.popover', 'body', (e) => {
    const popoverInstance = window.bootstrap.Popover.getInstance(e.target as HTMLElement);
    const popover = getPopoverContainerForTrigger(e.target as HTMLElement);
    if (popover) {
      // Enable any HTMX functionality in the popover.
      window.htmx.process(popover);

      // If the popover triggers a request, hide the popover when the request
      // finishes successfully. In the case of an error, leave it open so that
      // the user can try again.
      popover.addEventListener('htmx:afterRequest', (e: any) => {
        if (!e.detail.failed) {
          popoverInstance?.hide();
        }
      });
    }
  });

  // Hide other open popovers when a new popover is opened.
  on('show.bs.popover', 'body', () => {
    closeOpenPopovers();
  });

  on('shown.bs.popover', 'body', (e) => {
    const target = e.target as HTMLElement;

    const popoverInstance = window.bootstrap.Popover.getInstance(target);
    if (popoverInstance) {
      openPopovers.add(popoverInstance);
    }

    const container = getPopoverContainerForTrigger(target);
    if (container) {
      // Trap focus inside this new popover.
      const trap = trapFocus(container);

      // Remove focus trap when this popover is ultimately hidden.
      const removeFocusTrap = () => {
        trap.deactivate();
        target.removeEventListener('hide.bs.popover', removeFocusTrap);
      };
      target.addEventListener('hide.bs.popover', removeFocusTrap);

      // Place focus on the correct item inside the popover.
      const popoverBody = container.querySelector('.popover-body') as HTMLElement;
      focusFirstFocusableChild(popoverBody);
    }
  });

  on('hide.bs.popover', 'body', (e) => {
    const popoverInstance = window.bootstrap.Popover.getInstance(e.target as HTMLElement);
    if (popoverInstance) {
      openPopovers.delete(popoverInstance);
    }
  });

  // Close open popovers if the user hits the escape key.
  on('keydown', 'body', (e) => {
    if (e.key === 'Escape') {
      closeOpenPopovers();
    }
  });

  // Close open popovers if the user clicks outside of them.
  on('click', 'body', (e: MouseEvent) => {
    if (openPopovers.size == 0) return;

    // If this click occurred inside a popover, do nothing.
    const closestPopover = (e.target as HTMLElement).closest('.popover');
    if (closestPopover) return;

    // If this click occurred inside a date picker, do nothing.
    const closestDatePicker = (e.target as HTMLElement).closest('.flatpickr-calendar');
    if (closestDatePicker) return;

    // If this click is on a popover trigger element, do nothing.
    const popoverInstance = window.bootstrap.Popover.getInstance(e.target as HTMLElement);
    if (popoverInstance) return;

    // Close all open popovers.
    closeOpenPopovers();
  });
});
