import { on } from 'delegated-events';
import { observe } from 'selector-observer';

import { FlatpickrInstance } from '../lib/flatpickr.js';

/**
 * Attempts to close the flatpickr instance associated with a given element.
 * Returns true if the instance was closed, false if it was not.
 */
function closeFlatpickrForElement(el: HTMLElement | null): boolean {
  const closeFn = ((el as any)?._flatpickr as FlatpickrInstance)?.close;
  if (closeFn) {
    closeFn();
    return true;
  }
  return false;
}

// This handles our relatively common pattern of putting date inputs inside of
//popovers, e.g. for inline editing. When the popover is being hidden, we need
// to close the date picker so that it doesn't remain open even after its input
// has been removed from the DOM.
on('hide.bs.popover', 'body', (e) => {
  const target = e.target as HTMLElement;
  const popoverId = target.getAttribute('aria-describedby');
  if (!popoverId) return;
  const popoverContainer = document.querySelector(`#${popoverId}`);
  if (!popoverContainer) return;
  const maybeActiveFlatpickr =
    popoverContainer.querySelector<HTMLElement>('.flatpickr-input.active');
  closeFlatpickrForElement(maybeActiveFlatpickr);
});

// Close the date picker when the escape key is pressed. Note that we can't
// use event delegation here because we need to get to and capture this
// event before anything else.
observe('.flatpickr-input.active', {
  constructor: HTMLElement,
  initialize(el) {
    function escapeHandler(e: KeyboardEvent) {
      if (e.key !== 'Escape') return;

      if (closeFlatpickrForElement(e.target as HTMLElement)) {
        // This input may have been inside of a popover or a modal, so ensure the
        // event doesn't bubble up to that.
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
      }
    }
    return {
      add() {
        el.addEventListener('keydown', escapeHandler);
      },
      remove() {
        el.removeEventListener('keydown', escapeHandler);
      },
    };
  },
});
