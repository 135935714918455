import { observe } from 'selector-observer';

/**
 * Set the visibility of an element based on the state of a checkbox. Sets
 * visibility:hidden to hide the element and visibility:visible to show them, so
 * the elements still take up space in the DOM.
 *
 * Example:
 *  <input type="checkbox" id="my-checkbox" />
 *  <input type="text" data-visible-by="#my-checkbox" />
 *
 * or the same but where the checkbox needs to be un-checked to enable:
 *  <input type="checkbox" id="my-checkbox" />
 *  <input type="text" data-visible-by-not="#my-checkbox" />
 *
 * To customize the visible value, use the data-visible-value attribute:
 *  <input type="checkbox" id="my-checkbox" />
 *  <input type="text" data-visible-by="#my-checkbox" data-visible-value="block" />
 */

observe('[data-visible-by]', {
  constructor: HTMLElement,
  add(el) {
    initVisibleObserver(el);
  },
});

observe('[data-visible-by-not]', {
  constructor: HTMLElement,
  add(el) {
    initVisibleNotObserver(el);
  },
});

function initVisibleObserver(el: HTMLElement) {
  const checkboxSelector = el.getAttribute('data-visible-by');
  if (!checkboxSelector) return;

  const checkbox = document.querySelector<HTMLInputElement>(checkboxSelector);
  if (!checkbox) return;

  const toggleVisible = () => {
    if (checkbox.checked) {
      el.style.visibility = 'visible';
    } else {
      el.style.visibility = 'hidden';
    }
  };
  checkbox.addEventListener('change', toggleVisible);
  toggleVisible();
}

function initVisibleNotObserver(el: HTMLElement) {
  const checkboxSelector = el.getAttribute('data-visible-by-not');
  if (!checkboxSelector) return;

  const checkbox = document.querySelector<HTMLInputElement>(checkboxSelector);
  if (!checkbox) return;

  const toggleVisible = () => {
    if (!checkbox.checked) {
      el.style.visibility = 'visible';
    } else {
      el.style.visibility = 'hidden';
    }
  };
  checkbox.addEventListener('change', toggleVisible);
  toggleVisible();
}
